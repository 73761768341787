<template>
  <div class="">
    <vue-data-table
      :cols="[
        {
          header: 'Name',
          value: 'name',
        },
        {
          header: 'SKU',
          value: 'sku'
        },
        {
          header: 'Price',
          value: 'price'
        },
        {
          header: 'Categories',
          value: 'categories'
        },
        {
          header: 'In Stock?',
          value: 'in_stock'
        },
        {
          header: 'Added At',
          value: 'created_at',
          sort: true
        },
        {
          header: 'Last Modified',
          value: 'updated_at',
          sort: true
        },
        {
          header: 'Actions',
          value: 'actions'
        }
      ]"
      path="products"
      :request-params="{
        is_part: true
      }"
    >
      <template #header-left>
        <vue-button
          :icon="['fas', 'plus-circle']"
          @click="$router.push({
            name: 'products.new',
            query: {
              part: true
            }
          })"
        >
          Create
        </vue-button>
      </template>
      <template v-slot:item.name="row">
        <p>{{ row.name }}</p>
        <p v-if="row.extra_data.component_name" class="text-xs">
          ({{ row.extra_data.component_name }})
        </p>
      </template>
      <template v-slot:item.price="row">
        <div class="w-full flex justify-between">
          <span>£</span>
          <span>{{ row.price.toFixed(2) }}</span>
        </div>
      </template>
      <template v-slot:item.categories="row">
        <div v-if="row.extra_data && row.extra_data.categories && Object.values(row.extra_data.categories).length > 0" class="flex flex-col whitespace-nowrap">
          <div v-for="(value, index) in row.extra_data.categories" :key="index">
            <font-awesome-icon
              :icon="['fas', value ? 'check-circle' : 'times-circle']"
              :class="{
                'text-green-700': value,
                'text-red-700': !value
              }"
              size="lg"
              fixed-width
            />
            <span>{{ getCategoryLabel(index) }}</span>
          </div>
        </div>
        <span v-else>
          N/A
        </span>
      </template>
      <template v-slot:item.in_stock="row">
        <font-awesome-icon
          :icon="['fas', row.extra_data && row.extra_data.visible ? 'check-circle' : 'times-circle']"
          :class="{
            'text-green-700': row.extra_data && row.extra_data.visible,
            'text-red-700': !row || !row.extra_data || (row && row.extra_data && !row.extra_data.visible)
          }"
          size="lg"
          fixed-width
        />
      </template>
      <template v-slot:item.created_at="row">
        {{ $momentDateTimeHuman(row.created_at) }}
      </template>
      <template v-slot:item.updated_at="row">
        {{ $momentDateTimeHuman(row.updated_at) }}
      </template>
      <template v-slot:item.actions="row">
        <router-link
          :to="{ path: `/parts/${row.id}` }"
          :aria-label="`Edit Part: ${row.name}`"
          class="inline-flex text-primary font-medium hover:bg-primary-light rounded px-2 py-2 cursor-pointer"
        >
          <font-awesome-icon :icon="['fas', 'edit']" fixed-width />
        </router-link>
      </template>
    </vue-data-table>
  </div>
</template>
<script type="text/javascript">
import HasCategoryLabels from '@/mixins/HasCategoryLabels'

export default {
  name: 'Parts',

  mixins: [
    HasCategoryLabels
  ]
}
</script>
